<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        <stemble-latex content="$pH$" />
        indicator chart:
      </p>
      <p class="mb-4">
        <v-img style="max-width: 600px" src="/img/assignments/ubcPHGraph.png" />
      </p>

      <p class="mb-2">
        Titration curve of
        <chemical-latex content="HCl" />
        with
        <chemical-latex content="NaOH" />
      </p>
      <p class="mb-2">
        (x-axis: volume of
        <chemical-latex content="NaOH" />
        added to a solution of
        <chemical-latex content="HCl" />
        ):
      </p>
      <p class="mb-4">
        <v-img style="max-width: 300px" src="/img/assignments/ubcEquivalencePointGraph.png" />
      </p>

      <p class="mb-2">
        Given the above
        <stemble-latex content="$pH$" />
        indicator chart and
        <chemical-latex content="HCl" />
        /
        <chemical-latex content="NaOH" />
        titration curve, which
        <stemble-latex content="$pH$" />
        indicator(s), other than phenolphthalein, could also be suitable for the
        <chemical-latex content="HCl" />
        /
        <chemical-latex content="NaOH" />
        titration?
      </p>

      <p v-for="option in options" :key="option.value" class="mb-3">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2">I think this because:</p>
      <p class="mb-2">
        <s-textarea v-model="inputs.input2" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUBC154PLQ6',
  components: {
    StembleLatex,
    ChemicalLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: null,
      },
      options: [
        {text: 'Crystal violet', value: 'violet'},
        {text: 'Bromothymol blue', value: 'blue'},
        {text: 'Phenol red', value: 'red'},
      ],
    };
  },
};
</script>
<style scoped></style>
